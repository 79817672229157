import React from 'react';
import { useTranslation } from 'react-i18next';
import { YMInitializer } from 'react-yandex-metrika';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider, GlobalStyles } from '@avangard/ui/theme';

import { useTextDirection } from '@lib/i18n';
import { createApolloClient } from '@lib/apollo';
import { renderRoutes, renderRedirects, ScrollToTop } from '@lib/routing';
import { YM_ID } from '@config/environment';
import { routes as configRoutes, redirects as configRedirects } from '@config/routes';
import { TrackWidget, YMWidget } from '@modules/shared/organisms';

const history = createHistory(window as any);
const apolloClient = createApolloClient();

const useSnackbarStyles = makeStyles({
    variantInfo: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantSuccess: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantWarning: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantError: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
});

const App = (): React.ReactElement | null => {
    const { i18n } = useTranslation();
    const { direction } = useTextDirection();

    const routes = Object.values(configRoutes);
    const redirects = Object.values(configRedirects);

    const snackbarClasses = useSnackbarStyles();

    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={{ language: i18n.language, direction }}>
                <SnackbarProvider
                    dense
                    maxSnack={3}
                    autoHideDuration={3000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    classes={snackbarClasses}
                >
                    <GlobalStyles />

                    <LocationProvider history={history}>
                        <ScrollToTop />

                        <Router basepath='/:lang'>
                            {renderRoutes(routes)}
                            {renderRedirects(redirects)}
                        </Router>

                        {YM_ID ? <YMWidget /> : null}
                        <TrackWidget />
                    </LocationProvider>

                    {YM_ID ? (
                        <YMInitializer accounts={[YM_ID]} options={{ webvisor: true }} />
                    ) : null}
                </SnackbarProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export { App };
